@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Staatliches&family=Thasadith:ital,wght@0,400;0,700;1,400;1,700&family=VT323&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'VT323', serif;
}

html {
    font-size: 1.3rem;
    color: rgb(0, 255, 51);
}

body {
    height: 100vh;
    width: 100vw;
    /* background-image: linear-gradient(rgb(230, 90, 230), rgb(224, 171, 224)); */
    background-color: black;
    display: flex;
}

#root {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 1rem, 0.5rem;
}

.Home,
.Register,
.Login,
.Chat,
.Verification,
.Profile {
    width: 100%;
    flex-grow: 1;
    padding: 1rem;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    width: 100%;
    list-style-type: none;
}

nav {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
}

.nav-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 1rem;
}

.nav-list li {
    align-content: center;
    display: inline-block;
}

.nav-left {
    text-align: left;
    flex-basis: 70%;
}

.nav-right {
    text-align: center;
    flex-basis: 8%;
}

.Footer {
    padding: 0.75rem;
    display: grid;
    place-content: center;
}

.Home {
    text-align: center;
}

.Home h1 {
    font-size: 4.5rem;
    margin-top: 8%;
}

#description {
    position: relative;
    top: 0.5rem;
    font-size: 1.2rem;
}

#enter {
    position: relative;
    top: 4rem;
    display: inline-block;
    border-radius: 6px;
    background-color: black;
    border: 5px ridge rgb(0, 255, 51);
    color: white;
    font-size: 2rem;
    padding: 1rem;
    width: 14rem;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

#enter span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#enter span::after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -1.5rem;
}

#enter:hover span {
    padding-right: 2rem;
}

#enter:hover span::after {
    opacity: 1;
    right: 0;
}

.Register,
.Login,
.Profile {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* overflow-y: auto; */
}

.Register h1,
.Login h1 {
    font-size: 1.7rem;
}

.register-form,
.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex-grow: 1; */
    padding-bottom: 1rem;
    padding-top: 1rem;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea,
button {
    font-size: 1.2rem;
    padding: 0.25rem;
    border-radius: 0.5rem;
}

.errMsg {
    background-color: rgb(249, 120, 188);
    color: firebrick;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background-color: #605e5e;
    color: white;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.Register button,
.Login button {
    margin-top: 1rem;
}

.Chat {
    height: 80vh;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.online {
    padding-bottom: 1rem;
}

.chat-form {
    width: 100%;
    margin: auto;
    max-width: 70%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 0.25rem;
}

.activity {
    height: 3rem;
    width: 70%;
    padding-top: 0.5rem;
}

.activity li {
    display: inline;
}

.chat-input {
    flex-grow: 1;
    max-width: calc(80% - .25rem);
}

.chat-submit {
    width: 20%;
}

.chat-container {
    overflow: auto;
    flex-grow: 1;
    width: 70%;
}

.chat-display {
    display: flex;
    flex-flow: column;
    justify-content: left;
    width: 100%;
    margin: 1rem auto;
}

.msg-name {
    display: inline-block;
    width: 7%;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    cursor: pointer;
}

.msg-separator {
    margin-right: 0.2rem;
    vertical-align: top;
}

.msg-text {
    display: inline-block;
    width: 80%;
    text-align: left;
    overflow-wrap: break-word;
}

.msg-time {
    display: none;
    width: 10%;
    text-align: right;
    vertical-align: top;
}

.msg-text:hover+.msg-time {
    display: inline-block;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: black;
}

::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bebcbc;
}

.admin {
    color: red;
}

.user {
    color: rgb(0, 255, 51);
}

.Verification {
    height: 80vh;
    text-align: center;
}

@keyframes slideIn {
    0% {
        transform: translateY(calc(100vh));
    }
}

.dialog {
    margin: auto;
    background-color: black;
    color: rgb(0, 255, 51);
    border-radius: 7px;
    width: 50%;
    height: 55%;
    max-width: 500px;

    animation: slideIn 0.5s;
}

.dialog-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.dialog-text {
    font-size: 1.2rem;
    width: 90%;
    height: 80%;
}

.dialog-button {
    width: 40%;
}

.profile-container {
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(0, 255, 51);
    border-radius: 7px;
    padding: 1rem;
}

.profile-container h2 {
    padding-bottom: 1rem;
}

.profile-item {
    padding-bottom: 1rem;
}

.profile-container textarea {
    font-size: 1.1rem;
    resize: none;
}

#bio-label {
    vertical-align: top;
}

@media screen and (max-width: 540px) {

    .Profile,
    .Register,
    .Login {
        width: 90%;
    }

    .nav-list {
        font-size: 0.7rem;
    }

    .nav-left {
        text-align: left;
        flex-basis: 40%;
    }

    .nav-right {
        text-align: center;
        flex-basis: auto;
    }

    .chat-container {
        width: 90%;
    }

    .chat-display {
        font-size: 0.7rem;
    }

    .msg-name {
        width: 12%;
    }

    .msg-text {
        width: 77%;
    }

    .msg-text:hover+.msg-time {
        display: none;
    }
}